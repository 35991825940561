
import React from "react";

const blockWrapStyle = {
  position: "relative",
  paddingTop: "40px",
  marginBottom: "50px",
};
const blockWrapAlignFullStyle = {
  position: "relative",
  width: "calc(100vw - 17px)",
  maxWidth: "100vw !important",
  left: "50%",
  transform: "translateX(-50%)",
  padding: "0 30px"
};
const Home = () => {
  return (
    <>
      <div style={blockWrapAlignFullStyle}>
        <div style={blockWrapStyle}>
          <div
            style={{
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "#f7fafb",
              position: "absolute",
              display: "block",
              content: "",
            }}
          ></div>
          <div
            style={{
              position: "relative",
              zIndex: 2,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              maxWidth: "1170px",
              padding: "0 30px",
              margin: "auto",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                width: "100%",
                maxWidth: "595px",
                paddingTop: "99px",
                paddingBottom: "30px",
                boxSizing: "border-box",
              }}
            >
              <p
                style={{
                  marginBottom: "39px",
                  fontSize: "34px",
                  lineHeight: "50px",
                  letterSpacing: "-1px",
                }}
              >
                <strong>Hello, I’m Nirvana Choudhury.</strong>
                <span
                  style={{
                    display: "inline-block",
                    width: "93px",
                    height: "2px",
                    margin: "0 35px",
                    verticalAlign: "middle",
                    backgroundColor: "#001828",
                  }}
                ></span>
                <br></br>I’m a mathematician, tech innovator, astronomer,
                writer, and the inventor of Tachyon.
              </p>
            </div>
            <div
              style={{
                position: "relative",
                alignSelf: "center",
                maxWidth: "450px",
                marginBottom: "-78px",
                marginLeft: "48px",
                transform: "translateX(48px)",
                opacity: 0,
                animationDuration: "0.5s",
                animationTimingFunction: "ease-in-out",
                animationFillMode: "both",
                animationName: "fadeInRight",
              }}
              className={"fadeInRight animationGo"}
            >
              <style>{`
            @keyframes fadeInRight {
                 0% { opacity: 0; transform: translateX(500px); }
                 100% { opacity: 1; transform: translateX(0); }
            }
        `}</style>
              <div
                style={{
                  boxShadow: "0px 25px 60px rgba(17, 51, 75, 0.15)",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 1,
                  position: "absolute",
                  display: "block",
                  content: "",
                }}
              ></div>
              <img
                style={{
                  position: "relative",
                  zIndex: 3,
                  display: "block",
                  maxWidth: "100%",
                  height: "auto",
                  margin: "auto",
                }}
                width="900"
                height="1160"
                src={`${process.env.PUBLIC_URL}/assets/images/nirvana-profile-pic.png`}
                className={"attachment-full size-full"}
                alt=""
                sizes="(max-width: 900px) 100vw, 900px"
              />{" "}
              <div
                style={{
                  right: "-74px",
                  bottom: "-65px",
                  width: "171px",
                  height: "151px",
                  background: `url(${process.env.PUBLIC_URL}/assets/images/brush-stroke-blue.svg) no-repeat center`,
                  backgroundSize: "contain",
                  position: "absolute",
                  display: "block",
                  content: "",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div style={blockWrapAlignFullStyle}>
        <style>
          {`
        .block.call-out .column:nth-child(n + 2) {
            margin-top: 72px;
        }
        .block.call-out .column {
            max-width: 300px;
        }
        .block.call-out p {
          margin: 0 0 29px;
          font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
          font-size: 20px;
          line-height: 29px;
        }
        a.underline::before {
          bottom: 4px;
          left: -2px;
          height: 4px;
          background-color: #cae5ee;
          transition: all 0.3s;
          position: absolute;
          display: block;
          content: '';
        }
        a.arrow {
          position: relative;
          font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
          font-size: 18px;
          font-style: italic;
          font-weight: bold;
          line-height: 26px;
          color: #001828;
          transition: all 0.3s;
        }
        a.arrow::before {
          right: 51px;
          opacity: 1;
        }
        a.blue::after {
          background-image: url(${process.env.PUBLIC_URL}/assets/images/link-arrow-blue.svg);
        }
        a.arrow::after {
          margin-right: 15px;
          top: 10px;
          right: 0;
          display: inline-block;
          width: 21px;
          height: 9px;
          margin-left: 17px;
          content: '';
          background-repeat: no-repeat;
          background-position: right;
          background-size: cover;
          transition: all 0.3s;
        }
        a.arrow span {
          position: relative;
          z-index: 1;
        }
      `}
        </style>
        <div style={{ marginBottom: "130px" }} className={"block call-out"}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
              maxWidth: "1110px",
              margin: "auto",
            }}
          >
            <div
              style={{
                maxWidth: "300px",
                opacity: 0,
                animationDuration: "0.5s",
                animationTimingFunction: "ease-in-out",
                animationFillMode: "both",
                animationName: "fadeInLeft",
              }}
              className={"fadeInLeft animationGo"}
            >
              <style>{`
            @keyframes fadeInLeft {
                 0% { opacity: 0; transform: translateX(-500px); }
                 100% { opacity: 1; transform: translateX(0); }
            }
        `}</style>
              <h2
                style={{
                  margin: 0,
                  fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif",
                  fontSize: "44px",
                  fontWeight: "bold",
                  lineHeight: "72px",
                  textShadow:
                    "2px 2px #fff, 2px -2px #fff, -2px 2px #fff, -2px -2px #fff, 0px 2px #fff, 0px -2px #fff, 2px 0px #fff, -2px 0px #fff",
                  textTransform: "none",
                  letterSpacing: "-1.5px",
                }}
              >
                Innovating with Computational 
                <div className={"beforeSpan"}>
                  <style>
                    {`
              @media (min-width: 690px)
              .beforeSpan {
                line-height: 60px;
                position: absolute;
                bottom: -4px;
                left: 24px;
                z-index: 1;
                display: block;
                width: 10px;
                height: 4px;
                content: '';
                background-color: #fff;
              }
              `}
                  </style>
                </div>
                <span
                  style={{
                    position: "relative",
                    display: "inline-block",
                    paddingBottom: "10px",
                    lineHeight: "60px",
                    borderBottom: "4px solid #001828",
                  }}
                >
                  Math.
                </span>
              </h2>
            </div>
            <div className={"column"}>
              <p>
                I’m a 9th grade student manifesting powerful ideas. Age is no
                longer a barrier for young visionaries who are deeply passionate
                about solving tough challenges through innovation. I taught
                myself how to use computational mathematics and programming to
                create impactful applications for the topics I am passionate
                about.
              </p>
            </div>
            <div className={"column"}>
              <p>
                This site reflects how I evolved to manifest my ideas and was
                created to inspire others like me to boldly pursue innovation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <style>{`
          .block {
            width: 100%;
            font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            color: #001828;
            webkitFontSmoothing: antialiased;        
          }
          .block.products .background {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            right: 0;
            bottom: 149px;
            left: 0;
            height: 110%;
            z-index: -1;
            background-color: #f8f8fa;
            background-position: bottom;
            background-size: cover;
          }
          .block.products {
              position: relative;
              padding-top: 61px;
              margin-bottom: 139px;
          }
          .block.products::before {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 250px;
            left: 0;
            height: 110%;
            z-index: -1;
            background-color: #fef7f3;
          }
          .container.padding {
            position: relative;
            max-width: 1170px;
            padding: 0 30px;
            margin: auto;
          }
          .row {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 81px;
          }
          .block.products .content {
            max-width: 498px;
            margin-top: 95px;
            margin-right: 20px;
          }
          .row .content h5 {
            margin: 0 0 23px;
          }
          .block.products .content h5 {
            color: #f26111;
          }
          .row .content p {
            margin: 0 0 36px;
            font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            font-size: 28px;
            line-height: 37px;
          }
          a.arrow {
            position: relative;
            font-family: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif";
            font-size: 18px;
            font-style: italic;
            font-weight: bold;
            line-height: 26px;
            color: #001828;
            transition: all 0.3s;
          }
          .animated.fadeInRight_onScroll.animationGo {
            animation-name: fadeInRight;
          }
          .animated {
            opacity: 0;
            animation-duration: 0.5s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: both;
          }
          .block.products .item {
            position: relative;
            width: 300px;
            max-width: 300px;
          }
          .block.products .item.featured {
            transition: all ease-in-out 0.3s;
            margin: 0 5px;
          }
          .block.products .item.featured:hover {
            transform: translatey(-3px);
          }
          @keyframes fadeInRight {
                0% { opacity: 0; transform: translateX(500px); }
                100% { opacity: 1; transform: translateX(0); }
          }
          .block.products .item:hover::before {
            box-shadow: 0px 31px 45px rgba(17, 51, 75, 0.08);
          }
          .block.products .item::before {
            position: absolute;
            display: block;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s;
          }
          .block.products .item:hover .inside {
            background-color: #fff;
          }
          .block.products .item .inside {
            position: relative;
            z-index: 2;
            padding: 20px;
            margin: -20px;
            transition: all 0.3s;
          }
          .block.products .item .image {
            box-shadow: 0px 20px 45px rgba(17, 51, 75, 0.15);
            transition: all 0.3s;
          }
          .block.products .item img {
            margin-bottom: 20px;
          }
          .block img  {
            display: block;
            max-width: 100%;
            height: auto;
            margin: auto;
          }
          .block a:not(.underline):not(.btn) {
            font-weight: inherit;
            color: inherit;
            border-bottom: none;
          }
          .block.products .item h5 {
            margin: 0 0 -3px;
          }
          .block.products .item:hover .underline {
            opacity: 1;
          }
          .block.products .item .underline {
            opacity: 0;
          }
          a.orange::before {
            background-color: #fcdfcf;
          }
          a.arrow::before {
            right: 51px;
            opacity: 1;
          }
          a.underline::before {
            position: absolute;
            display: block;
            content: '';
            bottom: 4px;
            left: -2px;
            height: 4px;
            transition: all 0.3s;
          }
          a.arrow span {
            position: relative;
            z-index: 1;
          }
          a.arrow::after {
            top: 10px;
            right: 0;
            display: inline-block;
            width: 21px;
            height: 9px;
            margin-left: 17px;
            margin-right: 15px;
            content: '';
            background-repeat: no-repeat;
            background-position: right;
            background-size: cover;
            transition: all 0.3s;
          }
          a.orange::after {
            background-image: url(${process.env.PUBLIC_URL}/assets/images/link-arrow-orange.svg);
          }
          a.arrow:hover::after {
            width: 36px;
            margin-right: 0;
            background-image: url(${process.env.PUBLIC_URL}/assets/images/link-arrow-black.svg);
          }
          .block.products.brush::after {
            position: absolute;
            display: block;
            content: '';
            top: -76px;
            left: 19px;
            width: 68px;
            height: 214px;
            background: url(${process.env.PUBLIC_URL}/assets/images/brush-stroke-products.svg) no-repeat center;
            background-size: contain;
          }
        `}</style>
      <div style={blockWrapAlignFullStyle}>
        <div className={"block products brush"}>
          <div className={"container padding"}>
            <div className={"row"}>
              <div className={"content"}>
                <h5>I am a Technology Inventor.</h5>
                <p>
                  I am the inventor of <strong>Tachyon, a lightweight dynamic
                  programming language</strong> that supports full multithreading and
                  prototype-based object-oriented programming.{" "}
                    I have also built Termite - a ternary virtual machine to
                    enable applications in cryptography.
                </p>
              </div>
              <div
                className={
                  "item featured animated fadeInRight_onScroll animationGo"
                }
              >
                <div className={"inside"}>
                  <div className={"image"}>
                    <a href="//tachyonlang.com/">
                      <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/tachyon-screenshot.png`}
                          className={"attachment-full size-full"}
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                    </a>
                  </div>
                  <h5>
                    <a href="//tachyonlang.com/">
                      <span>Tachyon</span>
                    </a>
                  </h5>
                  <a
                    className={"underline arrow orange"}
                    href="//tachyonlang.com/"
                    target=""
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={blockWrapAlignFullStyle}>
        <div className={"block products"}>
        <div
            className={"background"}
            style={{
              backgroundColor: 'rgb(247, 250, 251)',
            }}
          ></div>
          <div className={"container padding"}>
            <div className={"row"}>
            <div className={"content"}>
                <h5>I am an Astronomer.</h5>
                <p>
                  I create physics-based astronomical simulations using tools
                  such as Universe Sandbox &amp; Blender to share them on my youtube
                  channel. I am also in the process of creating an iOS app
                  “Surya” for exploring exoplanets in 3D.
                </p>
              </div>
              <div
                className={
                  "item featured animated fadeInRight_onScroll animationGo"
                }
              >
                <div className={"inside"}>
                  <div className={"image"}>
                  <a href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP">
                  <span>
                        <img
                          width="600"
                          height="838"
                          src={`${process.env.PUBLIC_URL}/assets/images/surya-screenshot.png`}
                          className={"attachment-full size-full"}
                          alt=""
                          sizes="(max-width: 600px) 100vw, 600px"
                        />
                      </span>
                      </a>
                  </div>
                  <h5>
                    <a href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP">
                      <span>Surya</span>
                    </a>
                  </h5>
                  <a
                    className={"underline arrow orange"}
                    href="//youtu.be/bjuC3mTk_08?si=1GAcwxr9aRUKz7nP"
                    target=""
                  >
                    <span>Learn More</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
